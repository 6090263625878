<template>
  <div class="bg-gray-300 h-screen">
    <!-- Barre de login -->
    <div class="bg-white flex flex-row-reverse p-1 mb-4 shadow-md">
      <button @click="logout" class="bg-amber-500 text-white px-4 py-2 mr-2 rounded hover:bg-orange-500">Se déconnecter</button><br><br>
    </div>
    <!-- Gestion des erreurs -->
    <div v-if="error.message" class="bg-red-200">
      Message: {{ error.message }}
      Erreur: {{ error.error }}
    </div>
    <!-- Gestion de la liste d'objets -->
    <div v-for="(obj, idx) in whereList" :key="idx" class="bg-white mx-4 p-4 rounded shadow mb-3 md:flex justify-between">
      <div>
        <div>
          <span class="font-bold text-lg">Zone:</span> <span class="text-gray-600">{{ obj.Name }}</span>
        </div>
        <div>
          <span class="font-bold text-lg">État:</span>
          <span class="text-gray-600" v-if="obj.State === 'open'"> Ouvert</span>
          <span class="text-red-500 font-bold" v-if="obj.State === 'opening process'"> Ouverture</span>
          <span class="text-gray-600" v-if="obj.State === 'close'"> Fermé</span>
          <span class="text-red-500 font-bold" v-if="obj.State === 'closing process'"> Fermeture</span>
          <span class="text-gray-600" v-if="obj.State === 'middle'"> Entrebâillé</span>
          <span class="text-red-500 font-bold" v-if="obj.State === 'middling process'"> Entrebâillement</span>
        </div>
      </div>
      <div class="md:flex md:items-center flex justify-center mt-3">
        <div v-if="obj.State === 'close' || obj.State === 'middle'">
          <button @click="makeAction('open', obj.Where)" class="m-1 shadow leading-tight bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-800">Ouvrir</button>
        </div>
        <div v-if="obj.State === 'open' || obj.State === 'middle'">
          <button @click="makeAction('close', obj.Where)" class="m-1 shadow leading-tight bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-800">Fermer</button>
        </div>
        <div v-if="obj.State === 'open' || obj.State === 'close'">
          <button @click="makeAction('middle', obj.Where)" class="m-1 shadow leading-tight bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-800">Entrebâiller</button>
        </div>
        <!-- Boutons inutiles -->
        <div v-if="obj.State === 'closing process' || obj.State === 'middling process'">
          <button class="m-1 shadow leading-tight bg-gray-400 text-white px-4 py-2 rounded cursor-wait">Ouvrir</button>
        </div>
        <div v-if="obj.State === 'opening process' || obj.State === 'middling process'">
          <button class="m-1 shadow leading-tight bg-gray-400 text-white px-4 py-2 rounded cursor-wait">Fermer</button>
        </div>
        <div v-if="obj.State === 'opening process' || obj.State === 'closing process'">
          <button class="m-1 shadow leading-tight bg-gray-400 text-white px-4 py-2 rounded cursor-wait">Entrebâiller</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShuttersComp',
  props: {
  },
  data() {
    return {
      config: {
        exp: '',
        token: '',
      },
      deltaTime: undefined,
      timeout: undefined,
      error: {},
      whereList: [],
    };
  },
  methods: {
    callRefreshToken() {
      this.refreshToken();
      if (this.deltaTime > 0) {
        this.timeout = setTimeout(this.callRefreshToken, this.deltaTime);
      }
    },
    compare(a, b) {
      if (a.Name < b.Name) { return -1; }
      if (a.Name > b.Name) { return 1; }
      return 0;
    },
    getShuttersList() {
      this.axios.get('/api/v1/getList')
        .then((response) => {
          this.whereList = (response.data.whereList).sort(this.compare);
        })
        .catch((err) => {
          this.error = {
            'message': "Impossible d'obtenir la liste sur /api/v1/getList",
            'error': err,
          };
        });
    },
    loadConfigAtMount() {
      if (localStorage.config) {
        const config = JSON.parse(localStorage.config);
        const now = Math.floor(Date.now() / 1000);

        if (config.exp && (config.exp > now)) {
          this.config = config;
          this.deltaTime = (config.exp - now - 5) * 1000;
          if (this.deltaTime <= 0) {
            this.$router.push({ name: 'Auth' });
          } else {
            if (!this.axios.defaults.headers.common.Authorization) {
              this.axios.defaults.headers.common.Authorization = String.prototype.concat('Bearer ', config.token);
            }

            this.timeout = setTimeout(this.callRefreshToken, this.deltaTime);
          }
        } else {
          clearTimeout(this.timeout);
          this.$router.push({ name: 'Auth' });
        }
      } else {
        clearTimeout(this.timeout);
        this.$router.push({ name: 'Auth' });
      }
    },
    logout() {
      delete localStorage.config;
      clearTimeout(this.timeout);
      this.axios.defaults.headers.common.Authorization = '';
      this.$router.push({ name: 'Auth' });
    },
    makeAction(action, where) {
      let actualState;
      switch (action) {
        case 'open':
          actualState = 'opening process';
          break;
        case 'close':
          actualState = 'closing process';
          break;
        case 'middle':
          actualState = 'middling process';
          break;
      }
      for (let i=0; i<this.whereList.length; i++){
        if (this.whereList[i].Where === where) {
          this.whereList[i].State = actualState;
          break;
        }
      }
      this.axios.get('/api/v1/'.concat(action), {
        params: {
          where,
        },
      })
        .then(() => {
          for (let i=0; i < this.whereList.length; i++) {
            if (this.whereList[i].Where == where) {
              this.whereList[i].State = action;
              break;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    refreshToken() {
      this.axios.get('/api/v1/refresh')
        .then((response) => {
          const { token } = response.data;
          this.config.token = token;
          this.axios.defaults.headers.common.Authorization = String.prototype.concat('Bearer ', token);

          // Check jwt's expiration
          const jwtPayloadSplitted = token.split('.');
          const payload = JSON.parse(atob(jwtPayloadSplitted[1]));
          if (payload.exp) {
            const now = Math.floor(Date.now() / 1000);

            this.config.exp = payload.exp;
            this.deltaTime = (payload.exp - now - 5) * 1000;
          }

          // Save new config to localStorage
          localStorage.config = JSON.stringify(this.config);
        })
        .catch((err) => {
          console.log('erreur :', err);

          clearTimeout(this.timeout);
          this.axios.defaults.headers.common.Authorization = '';
          delete localStorage.config;
          this.$router.push({ name: 'Auth' });
        });
    },
  },
  mounted() {
    this.loadConfigAtMount();
    this.getShuttersList();
  },
};
</script>

<style lang="css" scoped>
</style>