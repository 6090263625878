<template>
  <div class="bg-gray-200 h-screen w-sceen flex content-center justify-center items-center">
    <div class="bg-white shadow-lg max-w-lg rounded md:flex">
      <div class="flex-1 w-full">
        <img class="w-full h-40 object-cover rounded-t md:h-full md:rounded-tr-none md:rounded-bl" src="../assets/connection.jpg" alt="Plage">
      </div>
      <div class="p-4 flex-1 md:flex md:flex-col md:items-center">
        <h2 class="text-2xl font-bold text-gray-800 mb-2 flex justify-center">
          Connexion
        </h2>
        <div v-if="error.message" class="bg-red-200 break-all m-3 p-1 rounded">
          <div>
            <span class="font-bold">Erreur:</span> <span class="text-gray-800">{{ error.error }}</span>
          </div>
          <div>
            <span class="font-bold">Message:</span> <span class="text-gray-800">{{ error.message }}</span>
          </div>
        </div>
        <form id="login" @submit.prevent="submitForm">
          <div class="mb-4">
            <label for="name" class="block text-gray-600 mb-2">Nom d'utilisateur</label>
            <input id="name" v-model="username" type="text" name="name" class="border shadow-inner py-2 px-3 text-gray-700 w-full focus:shadow-outline">
          </div>
          <div class="mb-4">
            <label for="password" class="block text-gray-600 mb-2">Mot de passe</label>
            <input id="password" v-model="password" type="password" name="password" class="border shadow-inner py-2 px-3 text-gray-700 w-full focus:shadow-outline">
          </div>
          <div>
            <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Se connecter</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthComp',
  props: {
  },
  data() {
    return {
      username: null,
      password: null,
      url: null,
      config: {
        exp: '',
        token: '',
      },
      error: {},
    };
  },
  methods: {
    submitForm() {
      const url = 'login';

      this.axios.post(url, {
        username: this.username,
        password: this.password,
      })
        .then((response) => {
          if (response.data.token) {
            const { token } = response.data;
            this.config.token = token;
            this.axios.defaults.headers.common.Authorization = String.prototype.concat('Bearer ', token);
            // Check jwt's expiration
            const jwtPayloadSplitted = token.split('.');
            const payload = JSON.parse(atob(jwtPayloadSplitted[1]));
            if (payload.exp) {
              this.config.exp = payload.exp;
            }

            localStorage.config = JSON.stringify(this.config);
            this.$router.push({ name: 'Shutters' });
          } else {
            this.error = {
              'error': 'oops',
              'message': 'Pas de token dans le message de réponse',
            };
          }
        })
        .catch((err) => {
          delete localStorage.config;

          this.error = {
            'error': err,
            'message': err.response.data.message,
          }
        });
    },
  },
  mounted() {
    // Si une configuration existe déjà
    if (localStorage.config) {
      const now = Math.floor(Date.now() / 1000);
      const config = JSON.parse(localStorage.config);

      // Load config if exp is valide
      if (config.exp && config.exp > now) {
        this.config = config;
        // Put token in headers
        this.axios.defaults.headers.common.Authorization = String.prototype.concat('Bearer ', config.token);
        this.$router.push({ name: 'Shutters' });
      } else {
        delete localStorage.config;
      }
    }
    // Si aucune configuration n'existe, je ne fais rien
  },
};
</script>

<style lang="scss" scoped>
</style>
