<template>
  <Shutters />
</template>

<script>
import Shutters from '../components/Shutters_comp.vue';

export default {
  name: 'ShuttersVue',
  components: {
    Shutters,
  },
};
</script>